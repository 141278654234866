body {
  background-color: var(--light);
}

small {
  line-height: 1.25;
}

label {
  margin-bottom: 0;
}

.card-header {
  background: var(--white);
}

.card-footer {
  background: var(--white);
}

.btn.nav-link.active {
  background: var(--light);
  border-color: #dee2e6 #dee2e6 var(--light);
}

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}
.callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout code {
  border-radius: 3px;
}
.callout + .bs-callout {
  margin-top: -5px;
}

.callout-default {
  border-left-color: #777;
}
.callout-default h4 {
  color: #777;
}

.callout-primary {
  border-left-color: var(--primary);
}
.callout-primary h4 {
  color: var(--primary);
}

.callout-success {
  border-left-color: var(--success);
}
.callout-success h4 {
  color: var(--success);
}

.callout-danger {
  border-left-color: var(--danger);
}
.callout-danger h4 {
  color: var(--danger);
}

.callout-warning {
  border-left-color: var(--warning);
}
.callout-warning h4 {
  color: var(--warning);
}

.callout-info {
  border-left-color: var(--info);
}
.callout-info h4 {
  color: var(--info);
}

.callout-bdc {
  border-left-color: #29527a;
}
.callout-bdc h4 {
  color: #29527a;
}

.select-sm {
  font-size: .8em;
}

.bg-gradient {
   background: linear-gradient(270deg, #e36b42, #523081, #74caac, #42b094);
    background-size: 800% 800%;

    -webkit-animation: AnimationName 59s ease infinite;
    -moz-animation: AnimationName 59s ease infinite;
    animation: AnimationName 59s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
